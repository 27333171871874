<template>
  <v-row justify="center">
    <!-- หน้าจอปกติ -->
      <v-dialog v-model="shower"  max-width="500px" persistent v-if="resolutionScreen >= 500">
        <v-card>
          <v-card-title>
            <div class="text-center" style="color:#333333">
              <span>{{ $t("admin.two_factor_authen") }}</span>
            </div>
          <v-flex class="d-flex justify-end">
            <v-btn 
            large 
            color="grey"
            icon
            @click="$emit('closedialog')"
            >
            <v-icon>
             mdi-close-circle
            </v-icon>
            </v-btn>
          </v-flex>
          </v-card-title>
          <v-divider></v-divider>
            <!-- {{data.current_status}} -->
            <v-layout class="pl-8 mt-2">
              <span v-if="$t('default') === 'th'">ข้อมูลการแก้ไข</span>
              <span v-else>Information Editing</span>
            </v-layout>
            
            <v-card-text>
            <v-layout justify-space-between style="align-items:center;" class="pa-2 mt-n2">
              <div>
                <b>{{$t('default') === 'th'? 'ชื่อผู้ดำเนินการ': 'Name'}} : </b>
                <span v-if="$t('default') === 'th'">{{name_th === null ? 'ระบบ' : name_th}}</span>
                <span v-else>{{name_eng === null ? 'System' : name_eng}}</span>
                <br>
                <b>{{ $t("consent.modified_date") }} : </b> {{formatdatetime(data.last_dtm)}}
              </div>
              <div>
              <v-switch
              v-model="switch1"
              @change="check_two_factor_authen()"
              inset
              :label="status === 'Y' ? $t('admin.enable') : $t('admin.disable')"
            ></v-switch>
              </div>
            </v-layout>
            </v-card-text>
            <v-card-text>
              <span style="font-size:11px;color:#C22B2B;">{{$t("information.twofactorauthen")}}</span>
            </v-card-text>
            <!-- <v-layout justify-center align-center>
            <v-list-item-action class="darkmode">
              <v-btn-toggle
                v-model="status"
                rounded
                dense 
                :mandatory="status !== ' '"
                :color="status === 'Y' ? 'green' : 'red'"
              >
                <v-btn value="Y">{{ $t("admin.enable") }}</v-btn>
                <v-btn value="N">{{ $t("admin.disable") }}</v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-layout> -->
            <v-divider></v-divider>
            <div class="text-center pa-3">
              <v-btn
                outlined
                color="#259B71"
                @click="update_two_factor_authen()"
                :disabled="check_button === false"
              >
                {{$t("admin.save")}}
              </v-btn>
            </div>
          
        </v-card>
      </v-dialog>
      <!-- หน้าจอมือถือ -->
      <v-dialog 
        v-model="shower" 
        scrollable
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
        v-else >
        <v-card>
        <v-card-text class="pa-5" style="overflow-y: hidden;">
            <v-layout class="pt-2 pb-1">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">
                  {{ $t("admin.two_factor_authen") }}
                </span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="$emit('closedialog')"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-center mt-5 mb-n2">
              <v-img
                :src="require('@/assets/img/logo_2fa.png')"
                max-width="68"
                max-height="68"
                class="mx-auto"
              />
            </div>
            <v-card-text>
            <v-layout justify-center>
              <div>
              <v-switch
              v-model="switch1"
              @change="check_two_factor_authen()"
              inset
              :label="status === 'Y' ? $t('admin.enable') : $t('admin.disable')"
            ></v-switch>
              </div>
            </v-layout>
            <v-layout justify-center class="mt-2 mb-2" style="font-size: 16px; font-weight: 600;">
              <span v-if="$t('default') === 'th'">ข้อมูลการแก้ไข</span>
              <span v-else >Information Editing</span>
            </v-layout>
            <v-layout justify-center>
              <div>
                <b>{{$t('default') === 'th'? 'ชื่อผู้ดำเนินการ': 'Name'}} : </b>
                <span v-if="$t('default') === 'th'">{{name_th === null ? 'ระบบ' : name_th}}</span>
                <span v-else>{{name_eng === null ? 'System' : name_eng}}</span>
                <br>
                <b>{{ $t("consent.modified_date") }} : </b> {{formatdatetime(data.last_dtm)}}
              </div>
            </v-layout>
            </v-card-text>
          </v-card-text>
          <v-card-text>
              <span style="font-size:11px;color:#C22B2B;">{{$t("information.twofactorauthen")}}</span>
            </v-card-text>
          <v-divider></v-divider>
          <v-card-actions  style="justify-content: center;">
            <v-btn
              style="color: white;"
              large
              :color="color.theme"
              @click="update_two_factor_authen()"
              :disabled="check_button === false"
            >
              {{ $t("admin.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-row>
</template>
<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show","data"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
      status:'',
      name_eng:'',
      name_th:'',
      switch1:false,
      check_button:false,
      // data:{
      //   update_by:{
      //     name_th:'',
      //     name_eng:''
      //   },
      //   current_status:''
      // }
    }
  },
  computed:{
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 400
      } else {
        // return 346
        return 400
      }
    },
    shower: {
      get() {
        if (this.show === true) {
        //   console.log("show",this.show);
        // ให้ปุ่ม disable
        this.check_button = false;
        if(this.data.current_status === 'Y'){
          this.switch1 = true;
        }else{
          this.switch1 = false;
        }
        this.status = this.data.current_status;
        this.name_th = this.data.update_by.name_th;
        this.name_eng = this.data.update_by.name_eng;
        }
        return this.show
      }
    }
  },
  methods:{
    check_two_factor_authen(){
      if(this.switch1 === true){
        // เลือก เปิดใช้งาน
        this.switch1 = true;
        this.status = 'Y';
        // เช็คปุ่มให้เปิดเมื่อมีการ switch เปลี่ยนค่า
        if(this.data.current_status === 'Y'){
          // ไม่ทำการ switch เปลี่ยนค่า = ปิด
          this.check_button = false;
        }else{
          // ทำการ switch เปลี่ยนค่า = เปิด
          this.check_button = true;
        }
      }else{
        // เลือก ปิดใช้งาน
        this.switch1 = false;
        this.status = 'N';
        // เช็คปุ่มให้เปิดเมื่อมีการ switch เปลี่ยนค่า
        if(this.data.current_status === 'N'){
          // ไม่ทำการ switch เปลี่ยนค่า = ปิด
          this.check_button = false;
        }else{
          // ทำการ switch เปลี่ยนค่า = เปิด
          this.check_button = true;
        }
      }
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
     async update_two_factor_authen(){
      console.log("update two factor");
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        user_id: this.dataUsername,
        current_status:this.status

      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "api/business/update/two_factor_authen", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
            if (res.data.status == "OK") {
              console.log("update two factor Success",res);
            Toast.fire({
              icon: "success",
              title: this.$t("admintoast.settingsuccess"),
            });
            this.$emit('close');
          } 
          else {
            Toast.fire({
              icon: "error",
              title: res.data.errorCode + ': ' + res.data.errorMessage,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    }
  }
}
</script>